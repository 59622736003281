<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import ProductionInvoiceServices from '../Script/ProductionInvoiceServices.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionInvoiceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['deleteClick', 'editClick', 'statusClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: ProductionInvoiceServices.readProductionInvoiceQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionInvoice.ProductionInvoiceReponse == null){
                            return [];
                        }else{
                            return response.data.GetProductionInvoice.ProductionInvoiceReponse;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionInvoice.ProductionInvoiceReponse == null){
                            return 0;
                        }else{
                        return response.data.GetProductionInvoice.total;
                        }
                    },
                    model: {
                        fields: {
                            invoice_date: {type: "date"},
                            invoice_due_date: {type: "date"},
                            invoice_received_date: {type: "date"},
                            ppn_tax_percentage: {type: "number"},
                            exchange_rate: {type: "number"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", width: 200,title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "invoice_number", width: 200, title: "No. Invoice", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "invoice_date", width: 200, title: "Tanggal Invoice", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} },
                { field: "invoice_due_date", width: 200, title: "Jatuh Tempo Invoice", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(invoice_due_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} },
                { field: "invoice_received_date", width: 200, title: "Tanggal Terima Invoice", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(invoice_received_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} },
                { field: "ppn_tax_percentage", width: 200,title: "PPN Tax (%)", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;" }},
                { field: "currency_name", width: 200,title: "Mata Uang", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "exchange_rate", width: 200,title: "Exchange Rate", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;" }}, 
                { field: "notes", width: 200, title: "Catatan", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },                
                { field: "created_at", width: 200, title: "Tanggal Dibuat", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config    
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        var statusClick = this.$props.statusClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            editClick(dataItem.invoice_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.invoice_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.invoice_id);
        })

        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            statusClick(dataItem);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionWithStatusChangesButton("Production Invoice")
        },
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "permissionGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.production_invoice_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: true,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_name', title: "Nama Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'uom', title: "UOM", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'quantity', title: "Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, attributes: { "class": "k-text-right" } },
                    { field: 'discount', title: "Diskon (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, attributes: { "class": "k-text-right" },
                        template: "#= kendo.toString(discount, 'n') #" },
                    { field: 'price_per_unit', title: "Harga per Unit", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, attributes: { "class": "k-text-right" },
                        template: "#= kendo.toString(price_per_unit, 'n') #" },
                    { field: 'price', title: "Total Harga", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, attributes: { "class": "k-text-right" },
                        template: "#= kendo.toString(price, 'n') #" },
                    { field: 'vat_per_unit', title: "Vat per Unit", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, attributes: { "class": "k-text-right" },
                        template: "#= kendo.toString(vat_per_unit, 'n') #" },
                    { field: 'vat', title: "Total Vat", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, attributes: { "class": "k-text-right" },
                        template: "#= kendo.toString(vat, 'n') #" },
                ],
            })
        },
        changeStatus(status){
            this.dataSource=  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { 
                                query: ProductionInvoiceServices.readProductionInvoiceQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionInvoice.ProductionInvoiceReponse == null){
                            return [];
                        }else{
                            return response.data.GetProductionInvoice.ProductionInvoiceReponse;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionInvoice.ProductionInvoiceReponse == null){
                            return 0;
                        }else{
                        return response.data.GetProductionInvoice.total;
                        }
                    },
                    model: {
                        fields: {
                            invoice_date: {type: "date"},
                            invoice_due_date: {type: "date"},
                            invoice_received_date: {type: "date"},
                            ppn_tax_percentage: {type: "number"},
                            exchange_rate: {type: "number"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            });
        },
    },
    
}
</script>